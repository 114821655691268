import { isGmailAddress } from 'utils/userUtils';

export type RolloutRestriction =
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6787%3A63549&t=vyfHFVQrFiKsA4nc-0
  */
  | 'USERS_PAGE'
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6775%3A58914&t=agg1M8eS6FPQGNzH-0
  */
  | 'TRANSFER_WORKSPACE'
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6778%3A62339&t=vyfHFVQrFiKsA4nc-0
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6787%3A63998&t=vyfHFVQrFiKsA4nc-0
  */
  | 'PREMIUM_PLAN'
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6787%3A64258&t=agg1M8eS6FPQGNzH-0
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6787%3A63998&t=agg1M8eS6FPQGNzH-0
  */
  | 'INVITATION'
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6941%3A62415&t=oWnpXTmWY8RG2k2x-0
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6941%3A62417&t=oWnpXTmWY8RG2k2x-0

  */
  | 'SHARED_UNSUBS'
  /*
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6941%3A62416&t=oWnpXTmWY8RG2k2x-0
    https://www.figma.com/file/3RF2n0DtbS5ZmM8IdURKYI/%F0%9F%92%8C-Web-App-(Rebuild)?node-id=6941%3A62419&t=oWnpXTmWY8RG2k2x-0
  */
  | 'SHARED_BOUNCES'
  | 'MONTHLY_BILLING'
  | 'WORKSPACE_DASHBOARD'
  | 'MULTIPLE_PAYMENT';

export type RolloutRestrictions = { [key in RolloutRestriction]: boolean };

const defaultRestrictions: RolloutRestrictions = {
  USERS_PAGE: true,
  TRANSFER_WORKSPACE: true,
  PREMIUM_PLAN: true,
  INVITATION: true,
  SHARED_UNSUBS: true,
  SHARED_BOUNCES: true,
  MULTIPLE_PAYMENT: true,
  MONTHLY_BILLING: true,
  WORKSPACE_DASHBOARD: true,
};

const domainUserRestrictionSteps: Record<RolloutRestriction, number[]> = {
  USERS_PAGE: [1],
  TRANSFER_WORKSPACE: [1],
  PREMIUM_PLAN: [1, 2, 3],
  INVITATION: [1],
  SHARED_UNSUBS: [1, 2, 3],
  SHARED_BOUNCES: [1, 2, 3],
  MULTIPLE_PAYMENT: [1, 2],
  MONTHLY_BILLING: [1, 2, 3, 4, 5, 6],
  WORKSPACE_DASHBOARD: [1],
};

const gmailUserRestrictionSteps: Record<RolloutRestriction, number[]> = {
  USERS_PAGE: [1, 2],
  TRANSFER_WORKSPACE: [1],
  PREMIUM_PLAN: [1, 2, 3],
  INVITATION: [1, 2, 3],
  SHARED_UNSUBS: [1, 2, 3],
  SHARED_BOUNCES: [1, 2, 3],
  MULTIPLE_PAYMENT: [1, 2],
  MONTHLY_BILLING: [1, 2, 3, 4, 5, 6],
  WORKSPACE_DASHBOARD: [1],
};

const hasRestriction = (
  restrictionSteps: Record<RolloutRestriction, number[]>,
  type: RolloutRestriction,
  lastActivatedStep?: number | null,
): boolean => {
  const restrictions = restrictionSteps[type];

  if (!restrictions || !restrictions.length) return false;
  if (!lastActivatedStep) return true;

  return restrictions.includes(lastActivatedStep);
};

export const getRestrictions = (
  userEmail?: string,
  lastActivatedStep?: number | null,
): RolloutRestrictions => {
  if (userEmail === undefined) {
    return defaultRestrictions;
  }
  const restrictionSteps = isGmailAddress(userEmail)
    ? gmailUserRestrictionSteps
    : domainUserRestrictionSteps;

  return Object.keys(restrictionSteps).reduce(
    (map, type) => ({
      ...map,
      [type]: hasRestriction(
        restrictionSteps,
        type as RolloutRestriction,
        lastActivatedStep,
      ),
    }),
    {} as RolloutRestrictions,
  );
};
